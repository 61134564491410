import styled from 'styled-components';

export const LocationsTop = styled.div`
  position: relative;
  margin-top: 220px;
  font-family: 'HelveticaNeue-Light', arial, sans-serif;
  font-size: 20px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.6);
  @media (max-width: 1399px) {
    margin-top: 210px;
    font-size: 20px;
    line-height: 32px;
  }
  @media (max-width: 1199px) {
    margin-top: 310px;
  }
  @media (max-width: 991px) {
    margin-top: 0;
  }
`;

export const LocationTopClearFix = styled(LocationsTop)`
  :after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: ' ';
    clear: both;
    height: 0;
  }
`;
